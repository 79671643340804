<template>
  <div class="ad-about">
    <ad-header :bg="aboutBG"
               :mobile-bg="facadeIMG"
               height="470px"
               mobile-height="320px"
    >
      <div class="banner-text-container">
        <div class="en-title">
          <img :src="textAboutUs" alt="">
        </div>
        <h1 class="cn-title">关于我们</h1>
      </div>
    </ad-header>

    <ad-navs />

    <div class="content-container">
      <div class="title">
        <div class="tit">
          <h2>{{ company }}</h2>
          <p>{{ slogan }}</p>
        </div>
        <div class="pic">
          <img :src="logo" style="height: 64px">
        </div>
      </div>
      <div class="content">
        <img :src="facadeIMG" alt="">
        <p class="introduce">{{ introduce }}</p>
      </div>
    </div>

    <ad-footer />
  </div>
</template>

<script>
import { IMAGES } from "@/settings"
import store from "@/store"

export default {
  name: 'AboutUs',
  data() {
    return {
      textAboutUs: IMAGES.titleImage.aboutUs,
    }
  },
  computed: {
    company() { return store.getters.enterpriseName },
    slogan() { return store.getters.enterpriseSlogan },
    introduce() { return store.getters.enterpriseIntroduce },
    logo() { return store.getters.enterpriseLogo },
    aboutBG() { return store.getters.resourceAboutBG },
    facadeIMG() { return store.getters.resourceFacadeIMG },
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .ad-about {
    .banner-text-container {
      display: none !important;
    }
    .content-container {
      width: 100%;
      max-width: 100%;
      margin: -50px auto auto auto !important;
      padding: 20px;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      .title {
        width: 100% !important;
        height: auto !important;
        padding-top: 30px !important;
        .tit {
          h2 {
            font-size: 18px !important;
          }
          p {
            font-size: 9px !important;
          }
        }
        .pic {
          display: none !important;
          img {
            height: 54px;
          }
        }
      }
      //img {
      //  width: 100% !important;
      //}
    }
    .content {
      width: 100% !important;
      margin: 30px auto auto auto !important;
      padding-bottom: 30px !important;
      img {
        display: none;
        width: 100% !important;
        height: auto !important;
      }
      p {
        font-size: 13px !important;
        margin-bottom: 30px !important;
        &:last-child {
          margin-bottom: 0px !important;
        }
      }
    }

    .footer-container {
      margin-top: 0px !important;
    }
  }
}
.ad-about {
  width: 100%;
  background: #f6f9fa;
  .banner-text-container {
    max-width: 1180px;
    height: 100%;
    text-align: center;
    padding-top: 175px;
    h1 {
      font-size: 20px;
      font-weight: 600;
      color: #ffffff;
      margin-top: 18px;
    }
  }

  .content-container {
    max-width: 1180px;
    box-sizing: border-box;
    margin: -170px auto auto auto;
    background: #ffffff;
    border-radius: 20px;
    position: relative;
    z-index: 10;
    .title {
      width: 948px;
      height: 170px;
      box-sizing: border-box;
      margin: 0px auto auto auto;
      display: flex;
      align-items: flex-start;
      padding-top: 100px;

      .tit {
        flex: 1;
        h2 {
          font-size: 30px;
          font-weight: 600;
          color: #222222;
        }
        p {
          font-size: 16px;
          font-weight: 400;
          color: #999999;
          letter-spacing: 2px;
        }
      }
    }
  }
  .content {
    width: 948px;
    margin: 80px auto;
    padding-bottom: 100px;
    img {
      width: 948px;
      height: 270px;
      margin-bottom: 50px;
      border-radius: 12px;
      object-fit: cover;
    }
    //p {
    //  font-size: 14px;
    //  font-weight: 400;
    //  color: #222222;
    //  line-height: 32px;
    //  margin-bottom: 45px;
    //}
    .introduce {
      font-size: 1.1rem;
      line-height: 2rem;
      font-weight: 400;
      color: #222222;
      //text-indent: 2em;       // 首行缩进
      white-space: pre-line;    // 识别换行
      overflow: hidden;
      margin-bottom: 45px;
    }
  }

  .footer-container {
    margin-top: 100px;
  }
}
</style>
